import { Intent } from '../../models/Intent'

const mutations = {
  START_STORE(state) {
    state.enabledApps = []
    state.apiCalls = []
    state.entities = []
    state.variables = []
    state.schedules = []
    state.bot = undefined
    state.botId = undefined
    state.intentions = []
  },

  UNSHIFT_SCHEDULE(state, schedule) {
    state.schedules = [schedule].concat(state.schedules)
  },
 
  SPLICE_SCHEDULE(state, _id) {
    const index = state.schedules.findIndex(schedule => {
      return schedule._id === _id;
    })
    state.schedules.splice(index, 1)
  },

  UPDATE_SCHEDULE(state, schedule) {
    let index = null
    state.schedules.some((element, i) => {
      if (element._id === schedule._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.schedules[index] = schedule
      const aux = JSON.parse(JSON.stringify(state.schedules))
      state.schedules = aux
    }
  },
  UPDATE_TAG(state, tag) {
    let index = null
    state.tags.some((element, i) => {
      if (element._id === tag._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.tags[index] = tag
      const aux = JSON.parse(JSON.stringify(state.tags))
      state.tags = aux
    }
  },
  SPLICE_TAG(state, _id) {
    const index = state.tags.findIndex(tag => {
      return tag._id === _id;
    })
    state.tags.splice(index, 1)
  },
  UNSHIFT_TAG(state, tag) {
    state.tags = [tag].concat(state.tags)
  },
  
  UNSHIFT_VARIABLE(state, variable) {
    state.variables = [variable].concat(state.variables)
  },
  SPLICE_VARIABLE(state, id) {
    const index = state.variables.findIndex(obj => obj._id === id)
    if(index !== -1){
      state.variables.splice(index, 1)
    }
  },
  UPDATE_VARIABLE(state, variable) {
    let index = null
    state.variables.some((element, i) => {
      if (element._id === variable._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.variables[index] = variable
      const aux = JSON.parse(JSON.stringify(state.variables))
      state.variables = aux
    }
  },

  UNSHIFT_API_CALL(state, apiCall) {
    state.apiCalls = state.apiCalls.concat(apiCall)
  },
  SPLICE_API_CALL(state, index) {
    state.apiCalls.splice(index, 1)
  },
  UPDATE_API_CALL(state, apiCall) {
    let index = null
    state.apiCalls.some((element, i) => {
      if (element._id === apiCall._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.apiCalls[index] = apiCall
      const aux = JSON.parse(JSON.stringify(state.apiCalls))
      state.apiCalls = aux
    }
  },

  SET_BOT(state, bot) {
    state.bot = bot
  },
  SET_BOT_PLAIN(state, bot) {
    state.botPlain = bot
  },

  SET_BOT_ID(state, botId) {
    state.botId = botId
  },
  SET_CURRENT_VERSION(state, currentVersion) {
    state.currentVersion = currentVersion
  },

  SET_VERSION(state, { versionId, versionFork, forkTemplate }) {
    state.bot.activeVersion = versionId
    state.bot.fork = versionFork
    state.bot.forkTemplate = forkTemplate
  },

  // Intentions

  SET_INTENTIONS(state, intentions) {
    state.intentions = intentions.map(intent => {
      return new Intent(
        intent.name,
        intent.examples,
        intent.derivesToAgent,
        intent._id
      )
    })
  },

  SET_CREDENTIAL(state, credential) {
    if (state.bot) {
      state.bot.credential = credential
    }
  },

  SET_BOT_VERSIONS(state, botVersions) {
    state.botVersions = botVersions
  },
}

export default mutations
