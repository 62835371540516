import language from "@/languages/languages";
import get from 'lodash/get'

export default {
  NAVBAR_SET_PAGE_TITLE: ({ commit }, { pathTitle, lang}) => {
    commit('SET_NAVBAR_CURRENT_PATH', pathTitle)
    commit('SET_NAVBAR_CURRENT_PAGE', get(language, `${pathTitle}.${lang}`)); 
  },
  NAVBAR_PAGE_UPDATE_LANG: ({ commit, state }, lang) => {
    commit('SET_NAVBAR_CURRENT_PAGE', get(language, `${state.navbarLangPathTitle}.${lang}`)); 
  },
  NAVBAR_CLEAN_TITLE: ({ commit }) => {
    commit('SET_NAVBAR_CURRENT_PAGE', ''); 
  }
};
