import Request from './request'
import state from '../store/state'

const PATH = {
  VARIABLE: '/variables'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getVariables = (versionId = null, dataTypeExcluded = null, offset = null, limit = null, labelContains) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let query = '?'
  if(offset && limit) {
    query += `offset=${offset}&limit=${limit}`
  }
  if (Array.isArray(versionId)) {
    versionId.forEach(id => {
      query+= `&where[version]=${id}`
    })
  } else {
    query += (versionId) ? `&where[version]=${versionId}` : ''
  }

  if(dataTypeExcluded){
    if (Array.isArray(dataTypeExcluded)) {
      dataTypeExcluded.forEach(item => {
        query += `&exclude[dataType]=${item}`
      })
    } else {
      query += `&exclude[dataType]=${dataTypeExcluded}`;
    }
  }

  if (labelContains && labelContains.length > 0) {
    query += `&where[label][contains]=${labelContains}`;
  }

  return Request.get(`${state.config.konecta}${PATH.VARIABLE}${query}`, config)
}

const getAllVariables = (dataTypeExcluded=null, offset = 0, limit = 100, serviceId, populate = null, ninServiceId = false, labelContains = '') => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let query = ''

  query = `?offset=${offset}&limit=${limit}`

  if(dataTypeExcluded){
    if(Array.isArray(dataTypeExcluded)){
      dataTypeExcluded.forEach(item => {
        (query === '')? query = `&exclude[dataType]=${item}`:query = query + `&exclude[dataType]=${item}`
      })
    }else{
      query = query + `&exclude[dataType]=${dataTypeExcluded}`;
    }
  }

  if(serviceId){
    query = query + `&where[service]${ninServiceId ? '[$nin]': ''}=${serviceId}`
  }

  if(populate) {
    query = query + `&populate=${JSON.stringify(populate)}`
  }

  if (labelContains && labelContains.length > 0) {
    query += `&where[label][contains]=${labelContains}`;
  }

  return Request.get(`${state.config.konecta}${PATH.VARIABLE}${query}`, config)
}

const getVariable = variableId => {
    const token = _getToken()
    const config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }

    return Request.get(`${state.config.konecta}${PATH.VARIABLE}/${variableId}`, config)
}

const createVariable = payload => {
  const token = _getToken()
  const config = {
      headers: {
          authorization: `Bearer ${token}`
      }
  }
  return Request.post(`${state.config.konecta}${PATH.VARIABLE}`, payload, config)
}

const updateVariable = (id, payload) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.put(`${state.config.konecta}${PATH.VARIABLE}/${id}`, payload, config)
}

const deleteVariable = (variableId, force = false) => {
    const token = _getToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.delete(
      `${state.config.konecta}${PATH.VARIABLE}/${variableId}${force ? '?force=true' : ''}`, config
    )
  }

export default {
  getVariables,
  getVariable,
  createVariable,
  updateVariable,
  deleteVariable,
  getAllVariables
}
