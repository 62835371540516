import Request from './request'
import state from '../store/state'

const PATH = {
  TEMPLATE: '/template'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getAll = ({ version, service, groups, limit = 10, offset = 0, searchBy, search, sortBy, sort }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let query = `limit=${limit}&offset=${offset}`
  if (groups) {
    query = `${query}`
    const groupsQuery = groups.map((group, index) => `where[group][$in][${index}]=${group}`)
    query = `${query}&${groupsQuery.join('&')}`
  }

  if (searchBy && search) {
    query = `${query}&where[${searchBy}][$contains]=${search}`
  }

  if (sort && sortBy) {
    const sortKeys = {
      'asc': 1,
      'desc': -1
    }
    query = `${query}&sort[${sort}]=${sortKeys[sortBy]}`
  } else {
    query = `${query}&sort[_id]=-1`
  }

  if (version) query = `${query}&where[version]=${version}`
  if (service) query = `${query}&where[service]=${service}`
 
  const url = `${state.config.konecta}${PATH.TEMPLATE}?${query}`

  return Request.get(url, config)
}

const createOne = (data) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const url = `${state.config.konecta}${PATH.TEMPLATE}`
  return Request.post(url, data, config)
}

export default {
  getAll,
  createOne
}
