export default {
  SET_TEMPLATES(state, data) {
    state.templates = data
  },
  SET_TEMPLATES_TOTAL(state, data) {
    state.templatesTotal = data
  },
  SET_TEMPLATES_NEW_INSTALLED(state, { index, templateInstalled }) {
    state.templates[index]['template-installed'] = templateInstalled
  },
  ADD_TEMPLATE(state, data) {
    state.templates.push(data)
  }
};
