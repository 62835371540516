import BotMarketplaceService from '@/services/botMarketplace.service'

export default {
 MARKETPLACE_APPLICATIONS_GET_ALL_INSTALLED: async ({ commit }, queryParams) => {
    return BotMarketplaceService
      .getInstalledApps(queryParams)
      .then((resp) => {
        commit('SET_MARKETPLACE_APPLICATIONS_INSTALLED', resp?.data?.data);
        return resp.data.result;
      });
  },
  MARKETPLACE_APPLICATIONS_GET_ALL: async ({ commit }, queryParams) => {
    return BotMarketplaceService
      .getAvailableApps(queryParams)
      .then((resp) => {
        commit('SET_MARKETPLACE_APPLICATIONS', resp?.data?.data);
        return resp?.data?.data;
      });
  }
};
