import TemplateService from '@/services/template.service'

export default {
  TEMPLATES_GET_ALL: ({ commit }, queryParams) => {
    return TemplateService
      .getAll(queryParams)
      .then((resp) => {
        commit('SET_TEMPLATES', resp?.data?.result)
        commit('SET_TEMPLATES_TOTAL', resp?.data?.length)
        return resp.data.result;
      });
  },
  TEMPLATES_ADD_INSTALLED: ({ commit, state }, templatesInstalled) => {
    templatesInstalled.map((templateInstalled) => {
      const templateIndex = state.templates.findIndex(({ _id }) => _id === templateInstalled.template);
      if (templateIndex !== -1) commit('SET_TEMPLATES_NEW_INSTALLED', { index: templateIndex, templateInstalled })
    })
    return state.templates
  },
  TEMPLATE_CREATE_ONE: ({ commit }, data) => {
    return TemplateService
      .createOne(data)
      .then((resp) => {
        commit('ADD_TEMPLATE', resp.data)
        return resp.data;
      });
  }
};
