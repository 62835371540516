<template>
  <button
    :disabled="disabled"
    :class="[
      `vs-switch-${color}`,
      {
        'vs-switch-active':isChecked || $attrs.checked
      }
    ]"
    :style="style"
    class="vs-component vs-switch"
  >
    <input
      :disabled="disabled"
      :value="value"
      class="input-switch vs-switch--input"
      type="checkbox"
      @input="toggleValue"
    >
    <span class="vs-circle-switch vs-switch--circle" />
  </button>
</template>

<script>
import _color from '@/assets/utils/color'
export default {
  name:'VxSlider',
  props:{
    value: { type: Boolean, required: true },
    disabled: { type: Boolean, default: false },
    color:{
      default:'primary',
      type:String
    },
  },
  emits: ['update:value', 'change'],
  computed:{
    style(){
      return {
        background: this.value?_color.getColor(this.color,1):null,
        width: `42px`
      }
    },
    isChecked(){
      return !!this.value
    },
  },
  methods:{
    toggleValue(evt){
      this.$emit('update:value', !this.value)
      this.$emit('change',evt)
    },
  },
}
</script>