import Request from './request'
import state from '../store/state'

const PATH = {
  TEMPLATE: '/template-installed',
  TEMPLATE_CREATE_BULK: '/template-installed/bulk'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getAll = ({ version, service, limit = 10, offset = 0 }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const query = `?version=${version}&service=${service}&limit=${limit}&offset=${offset}&sort[_id]=-1`
  const url = `${state.config.konecta}${PATH.TEMPLATE}?${query}`
  return Request.get(url, config)
}

const createBulk = ({ templates, bot }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url = `${state.config.konecta}${PATH.TEMPLATE_CREATE_BULK}/${bot}`
  return Request.post(url, { templates }, config)
}

export default {
  getAll,
  createBulk
}
