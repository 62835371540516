import TemplateInstalledService from '@/services/templateInstalled.service'

export default {
  TEMPLATES_INSTALLED_CREATE_BULK: async ({ commit, state }, body) => {
    return TemplateInstalledService
      .createBulk(body)
      .then((resp) => {
        commit('SET_TEMPLATE_INSTALLED', [...state.templatesInstalled, ...resp.data])
        return resp.data;
      });
  },
};
