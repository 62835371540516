import state from '../store/state'
import Request from './request'

const PATH = {
  GET_ALL: '/chart-builder',
  CREATE: '/chart-builder',
  DELETE: '/chart-builder'
}


const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getAll = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.GET_ALL}`,
    config
  )
}

const createOne = (chartBuilder) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.CREATE}`,
    chartBuilder,
    config
  )
}

const updateOne = (chartUpdate, _id) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    `${state.config.konecta}${PATH.CREATE}/${_id}`,
    chartUpdate,
    config
  )
}

const deleteOne = (_id) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    `${state.config.konecta}${PATH.DELETE}/${_id}`,
    config
  )
}

const getOne = (_id) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.GET_ALL}/${_id}`,
    config
  )
}

export default {
  getAll,
  createOne,
  updateOne,
  deleteOne,
  getOne,
}